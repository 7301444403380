<script>
  import { Tween } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import { interpolate } from 'd3-interpolate';

  // Props declaration
  let { x, value, yScale, width, i, fill } = $props();

  // Animation parameters
  const tweenParams = {
    duration: 250,
    easing: cubicOut,
    interpolate
  };

  // Variables to be animated
  let tY = new Tween(0, {
    ...tweenParams,
    delay: 300 + i * 50
  });
  let tFill = new Tween(fill, {
    ...tweenParams,
    delay: 800 + i * 50
  });

  // Run the animation via Tweens
  $effect(() => {
    // animate the bar height
    tY.target = value;
    // animate the bar fill color
    tFill.target = fill;
  });
</script>

<rect
  {x}
  {width}
  y={yScale(tY.current)}
  height={yScale(0) - yScale(tY.current)}
  fill={tFill.current}
  stroke="none" />
